import React, {useEffect, useState, useRef} from 'react'
import './feedbackSupport.css'
import {
    Routes,
    Route,
    useSearchParams,
    BrowserRouter,
    useNavigate
  } from "react-router-dom"


import TokenService from '../utilities/token.service'
import API from "../utilities/api"
import ErrorWindow from '../errorWindow/errorWindow'
import Header from '../header/header'
import Navigation from '../navigation/navigation'

import PhoneContact from '../images/phoneContact.png'
import EmailContact from '../images/emailContact.png'

import feedbackImage from '../images/feedback1.png'
import supportImage from '../images/support1.png'

import SuccessSubmission from '../successSubmission/successSubmission'

import { Rating } from 'react-simple-star-rating'

export default function FeedbackSupport() {

    const navigate = useNavigate()

    const [queryParameters] = useSearchParams()

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // ERROR RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // NAVIGATION RELATED ///////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [pageContentsDisplay, setPageContentsDisplay] = useState('block')
    const [navigationDisplay, setNavigationDisplay] = useState('none')

    function hidePage(){
        setPageContentsDisplay('none')
        setNavigationDisplay('block')
    }

    function displayPage(){
        setPageContentsDisplay('block')
        setNavigationDisplay('none')
    }


    const myFeedbackReference = useRef()
    const [feedbackSuccess, setFeedbackSuccess] = useState('none')

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Handle focus
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    function handleFocusTextArea(focused){

      if(myFeedbackReference.current.value == 'Start typing here...' && focused){
        myFeedbackReference.current.value = ''
      }

      else if(myFeedbackReference.current.value == '' && !focused){
        myFeedbackReference.current.value = 'Start typing here...'
      }
      
  }

  function updateCharacterCount(){
    let curString = myFeedbackReference.current.value
    setCharacterCount(curString.length)
  }

  const [characterCount, setCharacterCount] = useState(0)


  async function handleFeedbackSubmission(){

    if(submitLoad){
      return
    }

    setSubmitLoad(true)

     // API request to submit the submission
     await API.post('submitFeedback', {
      feedback: myFeedbackReference.current.value,
      rating: rating
  })
  .then(res => {
      console.log(res.data)
      setFeedbackSuccess('success')
      setSubmitLoad(false)
      setRating(0)
  })
  .catch(err => { 
      setErrorDetails(err)
      setErrorDisplay('block')
      setSubmitLoad(false)
  })

  }


  function closeSuccessWindow(){
    setFeedbackSuccess('none')
    setCharacterCount(0)
    myFeedbackReference.current.value = 'Start typing here...'
    setRating(0)
  }

  const [submitLoad, setSubmitLoad] = useState(0)

  let successfulFeedback = "Your submission has been successful! Thank you for providing feedback!"

  const [rating, setRating] = useState(0)

  const handleRating = (rate: number) => {
    setRating(rate)

  }


  return (
    <div className='screen_background feedback_support_screen_background'>
        <Header hideFunction={hidePage} openFunction={displayPage} navDisplay={navigationDisplay}></Header>
        <Navigation navigationDisplay={navigationDisplay} type={queryParameters.get("type")}></Navigation>
        <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
        <SuccessSubmission display={feedbackSuccess === 'success' ? true : false} message={successfulFeedback} close={closeSuccessWindow}></SuccessSubmission>

        <div className='feedback_support_screen_foreground' style={{display: pageContentsDisplay}} >
          <div className='feedback_support_section'>
            <div className='feedback_support_title_grid'>
                <img className='feedback_support_title_image' src={supportImage}></img>
                <div className='feedback_support_section_title'>Support</div>
            </div>
            <hr></hr>
            <div className='feedback_support_instructions'>We apologize you are experiencing an issue. Our team takes your unfortunate experience seriously. Therefore, we would love to help you work through the problem and resolve it as soon as possible. Please contact us with one of the following options below:</div>
            <div className='feedback_support_image_title_grid'>
              <img className='feedback_support_contact_image' src={PhoneContact}></img>
              <div className='feedback_support_contact_label'>(612) 425-4442</div>
            </div>
            <div></div>
            <div className='feedback_support_image_title_grid'>
              <img className='feedback_support_contact_image' src={EmailContact}></img>
              <div className='feedback_support_contact_label'>support@voterstack.com</div>
            </div>
          </div>

          <div className='feedback_support_section'>
          <div className='feedback_support_title_grid'>
                <img className='feedback_support_title_image' src={feedbackImage}></img>
                <div className='feedback_support_section_title'>Feedback</div>
              </div>
              <hr></hr>
            <div className='feedback_support_instructions'>We are constantly improving the user experience as well as our feature set. Please leave a detailed explanation of your experience using our services.</div>
            <Rating onClick={handleRating} initialValue={rating}/>
            
            <textarea maxlength="280" className='feedback_support_text_area' ref={myFeedbackReference} onChange={()=>updateCharacterCount()} onFocus={()=>handleFocusTextArea(true)} onBlur={()=>handleFocusTextArea(false)}>Start typing here...</textarea>
            <div className='feedback_support_character_count'>{characterCount}/280</div>
            <div style={{marginTop: '1.5rem'}} className='common_button common_ack_button' onClick={()=>handleFeedbackSubmission()} >Submit</div>
          </div>
        
        
        </div>
    </div>
  )
}
