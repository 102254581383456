import React, {useEffect, useState} from 'react'
import './myProfile.css'
import {
    Routes,
    Route,
    useSearchParams,
    BrowserRouter,
    useNavigate
  } from "react-router-dom"

import userImage from '../images/userDefault2.png'

import TokenService from '../utilities/token.service'
import API from "../utilities/api"
import ErrorWindow from '../errorWindow/errorWindow'
import Header from '../header/header'
import Navigation from '../navigation/navigation'


export default function MyProfile() {

    const navigate = useNavigate()

    const [queryParameters] = useSearchParams()

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // ERROR RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////
    // NAVIGATION RELATED ///////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [pageContentsDisplay, setPageContentsDisplay] = useState('block')
    const [navigationDisplay, setNavigationDisplay] = useState('none')

    function hidePage(){
        setPageContentsDisplay('none')
        setNavigationDisplay('block')
    }

    function displayPage(){
        setPageContentsDisplay('block')
        setNavigationDisplay('none')
    }



    useEffect(()=>{
        getUserInformation()
    },[])

    const [userData, setUserDate] = useState({})

    async function getUserInformation(){

        await API.post('getUserInformation', {})
        .then(res => {
            console.log(res)
            setUserDate(res.data)
            
        })
        .catch(err => { 
            setErrorDetails(err)
            setErrorDisplay('block')
        })
    }

  return (
    <div className='screen_background my_profile_screen_background'>
        <Header hideFunction={hidePage} openFunction={displayPage} navDisplay={navigationDisplay}></Header>
        <Navigation navigationDisplay={navigationDisplay} type={queryParameters.get("type")}></Navigation>
        <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>

        <div className='my_profile_screen_foreground' style={{display: pageContentsDisplay}} >
            {/* <div className='my_profile_page_title'>My Profile</div> */}
            <div className='my_profile_section'>
                <div className='my_profile_title_grid'>
                    <img className='my_profile_title_image' src={userImage}></img>
                    <div className='my_profile_section_title'>Profile</div>
                </div>
                <hr></hr>

                <div className='my_profile_section_name'>{userData.fullName}</div>
                <div className='my_profile_member_number'>Member ID: {userData.memberID}</div>
                

                <div className='my_profile_section_subtitle'>Date Of Birth:</div>
                <div className='my_profile_section_text'>{userData.dateOfBirth}</div>

                <div className='my_profile_section_subtitle'>Phone Number:</div>
                <div className='my_profile_section_text'>{userData.phoneNumber}</div>

                <div className='my_profile_section_subtitle'>Email Address:</div>
                <div className='my_profile_section_text'>{userData.emailAddress}</div>
                
                <div className='my_profile_section_subtitle'>Registration Status:</div>
                <div className='my_profile_section_text'>{userData.registeredVoter}</div>
                
                <div className='my_profile_section_subtitle'>Verification Date: </div>
                <div className='my_profile_section_text'>{userData.verificationDate}</div>
                
                <div className='my_profile_section_subtitle'>Expiration Date:</div>
                <div className='my_profile_section_text'>{userData.verificationExpiration}</div>
                
                
                <div style={{height: '20px'}}></div>

                <div className='my_profile_section_subtitle'>Verified Address</div>
                <div className='my_profile_section_text'>{userData.addressLineOne}
                <span style={{display: userData.addressLineTwo ? 'block' : 'none'}}>
                    <br></br>{userData.addressLineThree}
                </span>
                <br></br>{userData.addressLineThree}
                <br></br>{userData.addressLineFour}</div>
 
                <div style={{height: '50px'}}></div>
            </div>

            <div style={{height: '50px'}}></div>

        </div>
    </div>
  )
}
