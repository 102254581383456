import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import './signUp.css'
import '../common.css'
import AddressValidate from './addressValidate/addressValidate'
import PreviewCoverage from './previewCoverage/previewCoverage'
import BasicInformation from './basicInformation/basicInformation'
import IdentityProof from './identityProof/identityProof'
import MailProof from './mailProof/mailProof'
import ReviewInformation from './reviewInformation/reviewInformation'
import ErrorWindow from '../errorWindow/errorWindow'
import Payment from './payment/payment'

import mixpanel from 'mixpanel-browser';
mixpanel.init('1dba164ecdda445989be8b3d0b0271cc');


export default function SignUp() {
    var baseAddress = null

    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // dev code
      baseAddress = 'https://voterstack.com:5016/'
    } else {
      // production code
      baseAddress = 'https://voterstack.com:5015/'
    }


    // Base
    const navigate = useNavigate()

    // Error Related
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }

    useEffect(() => {
        window.scrollTo(0, 0)

        mixpanel.track("Page Visit", {'Page': 'SignUp'});
      }, [])

    const [clientSecretKey, setClientSecretKey] = useState(null)

    const [customerObject, setCustomerObject] = useState({
        IDPhoto: null,
        IDPhotoURL: null,
        addressLineOne: null,
        addressLineTwo: null,
        birthday: null,
        city: null,
        confirmPassword: null,
        dateOfBirth: null,
        emailAddress: null,
        ethnicity: null,
        firstName: null,
        gender: null,  
        lastName: null,
        mailPhoto: null,
        mailPhotoURL: null,
        password: null,
        phoneNumber: null,
        state: null,
        zipCode: null,
        agreement: null
    })

    const [representatives, setRepresentatives] = useState({
        townStatus: false,
        cityStatus: false,
        townshipStatus: false,
        countyStatus: false,
        schoolStatus: false,
        stateStatus: false,
        federalStatus: false,
        town: [{name: 'John Doe', term: 'Jan 1st 2000 - Dec 31st 2004',  photoURL: 'https://voterstack.com/officialPhotos/8a58da5a-e100-4b40-9a76-a97753a9f92b-TBHeadshotCircle.png', title: 'Mayor for the city district of Ogilvie', branch: 'Executive Branch'}],
        city: [{name: 'John Doe', term: 'Jan 1st 2000 - Dec 31st 2004', photoURL: 'https://voterstack.com/officialPhotos/8a58da5a-e100-4b40-9a76-a97753a9f92b-TBHeadshotCircle.png', title: 'Mayor for the city district of Ogilvie', branch: 'Executive Branch'}],
        township: [{name: 'John Doe', term: 'Jan 1st 2000 - Dec 31st 2004', photoURL: 'https://voterstack.com/officialPhotos/8a58da5a-e100-4b40-9a76-a97753a9f92b-TBHeadshotCircle.png', title: 'Mayor for the city district of Ogilvie', branch: 'Executive Branch'}],
        school: [{name: 'John Doe', term: 'Jan 1st 2000 - Dec 31st 2004', photoURL: 'https://voterstack.com/officialPhotos/8a58da5a-e100-4b40-9a76-a97753a9f92b-TBHeadshotCircle.png', title: 'Mayor for the city district of Ogilvie', branch: 'Executive Branch'}],
        county: [{name: 'John Doe', term: 'Jan 1st 2000 - Dec 31st 2004', photoURL: 'https://voterstack.com/officialPhotos/8a58da5a-e100-4b40-9a76-a97753a9f92b-TBHeadshotCircle.png', title: 'Mayor for the city district of Ogilvie', branch: 'Executive Branch'}],
        state: [{name: 'John Doe', term: 'Jan 1st 2000 - Dec 31st 2004', photoURL: 'https://voterstack.com/officialPhotos/8a58da5a-e100-4b40-9a76-a97753a9f92b-TBHeadshotCircle.png', title: 'Mayor for the city district of Ogilvie', branch: 'Executive Branch'}],
        federal: [{name: 'John Doe', term: 'Jan 1st 2000 - Dec 31st 2004', photoURL: 'https://voterstack.com/officialPhotos/8a58da5a-e100-4b40-9a76-a97753a9f92b-TBHeadshotCircle.png', title: 'Mayor for the city district of Ogilvie', branch: 'Executive Branch'}]
    }
        
    )

    const [slide, setSlide] = useState({
        addressValidate: true,
        previewCoverage: false,
        basicInformation: false,
        identityProof: false,
        mailProof: false,
        review: false,
        payment: false
    })

    const formData = new FormData()

    //////////////////////////////////////////////////////////////
    // addAddress
    //////////////////////////////////////////////////////////////
    function addAddress(address){
        let currentCustomerObject = customerObject
        currentCustomerObject = {...currentCustomerObject, ...address}
        setCustomerObject(currentCustomerObject)
    }

    //////////////////////////////////////////////////////////////
    // addBasicInformation
    //////////////////////////////////////////////////////////////
    function addBasicInformation(basicInformation){
        let currentCustomerObject = customerObject
        currentCustomerObject = {...currentCustomerObject, ...basicInformation}
        setCustomerObject(currentCustomerObject)
    }

    //////////////////////////////////////////////////////////////
    // addIdentityProof
    //////////////////////////////////////////////////////////////
    function addIdentityProof(photo){
        let currentCustomerObject = customerObject
        currentCustomerObject.IDPhoto = photo
        currentCustomerObject.IDPhotoURL = URL.createObjectURL(photo)
        //let photoObject = { IDPhoto: photo}
        //currentCustomerObject = {...currentCustomerObject, ...photoObject}
        setCustomerObject(currentCustomerObject)
    }

    //////////////////////////////////////////////////////////////
    // addMailProof
    //////////////////////////////////////////////////////////////
    function addMailProof(photo){
        let currentCustomerObject = customerObject
        currentCustomerObject.mailPhoto = photo
        currentCustomerObject.mailPhotoURL = URL.createObjectURL(photo)
        //let photoObject = { mailPhoto: photo}
        //currentCustomerObject = {...currentCustomerObject, ...photoObject}
        setCustomerObject(currentCustomerObject)
    }

    //////////////////////////////////////////////////////////////
    // createAccount
    //////////////////////////////////////////////////////////////
    async function createAccount(){

        formData.append("proof", customerObject.IDPhoto)
        formData.append("proof", customerObject.mailPhoto)
        formData.append("firstName", customerObject.firstName)
        formData.append("lastName", customerObject.lastName)
        formData.append("dateOfBirth", customerObject.dateOfBirth)
        formData.append("gender", customerObject.gender)
        formData.append("ethnicity", customerObject.ethnicity)
        formData.append("phoneNumber", customerObject.phoneNumber)
        formData.append("emailAddress", customerObject.emailAddress)
        formData.append("addressLineOne", customerObject.addressLineOne)
        formData.append("addressLineTwo", customerObject.addressLineTwo)
        formData.append("city", customerObject.city)
        formData.append("state", customerObject.state)
        formData.append("zipCode", customerObject.zipCode)
        formData.append("password", customerObject.password)
        formData.append("confirmPassword", customerObject.confirmPassword)
        formData.append("agreement", customerObject.agreement)

        return await fetch(baseAddress + "setCustomer", {
            method: "POST",
            body: formData
        })
        .then(res => {
            if (res.ok) return res.json()
            return res.json().then(json => Promise.reject(json))
        })
        .then(returnData => {
            let currentCustomerObject = customerObject
            currentCustomerObject.trackerID = returnData.trackerID
            setCustomerObject(currentCustomerObject)
            setClientSecretKey(returnData.clientSecret)

            return true
        })
        .catch(error => {
            console.log(error)
            setErrorDetails(error)
            setErrorDisplay('block')
            return false
        })
    }



    //////////////////////////////////////////////////////////////
    // advanceSlide
    //////////////////////////////////////////////////////////////
    function moveSlide(current, direction){
        window.scrollTo(0, 0)

        console.log(current + " " + direction)
        
        switch(current){
            case 'addressValidate':
                if(direction){  
                    setSlide({
                        addressValidate: false, 
                        previewCoverage: true, 
                        basicInformation: false, 
                        identityProof: false, 
                        mailProof: false,
                        review: false,
                        payment: false})

                        mixpanel.track(
                            "Coverage Checking",
                            {
                                "City": customerObject.city,
                                "ZipCode": customerObject.zipCode
                            }
                        );

                } else {
                    exitSignUp()
                }
                break
            case 'previewCoverage':
                if(direction){
                    setSlide({
                        addressValidate: false, 
                        previewCoverage: false, 
                        basicInformation: true, 
                        identityProof: false, 
                        mailProof: false,
                        review: false,
                        payment: false})

                        
                } else {
                    setSlide({
                        addressValidate: true, 
                        previewCoverage: false, 
                        basicInformation: false, 
                        identityProof: false, 
                        mailProof: false,
                        review: false,
                        payment: false})
                }    
                break
            case 'basicInformation':
                if(direction){
                    setSlide({
                        addressValidate: false, 
                        previewCoverage: false, 
                        basicInformation: false, 
                        identityProof: true, 
                        mailProof: false,
                        review: false,
                        payment: false})
                } else {
                    setSlide({
                        addressValidate: false, 
                        previewCoverage: true, 
                        basicInformation: false, 
                        identityProof: false, 
                        mailProof: false,
                        review: false,
                        payment: false})
                } 
                break
            case 'identityProof':
                if(direction){
                    setSlide({
                        addressValidate: false, 
                        previewCoverage: false, 
                        basicInformation: false, 
                        identityProof: false, 
                        mailProof: true,
                        review: false,
                        payment: false})
                } else {
                    setSlide({
                        addressValidate: false, 
                        previewCoverage: false, 
                        basicInformation: true, 
                        identityProof: false, 
                        mailProof: false,
                        review: false,
                        payment: false})
                }   
                break
            case 'mailProof':
                if(direction){
                    setSlide({
                        addressValidate: false, 
                        previewCoverage: false, 
                        basicInformation: false, 
                        identityProof: false, 
                        mailProof: false,
                        review: true,
                        payment: false})
                } else {
                    setSlide({
                        addressValidate: false, 
                        previewCoverage: false, 
                        basicInformation: false, 
                        identityProof: true, 
                        mailProof: false,
                        review: false,
                        payment: false})
                }   
                break
            case 'review':
                if(direction){
                    setSlide({
                        addressValidate: false, 
                        previewCoverage: false, 
                        basicInformation: false, 
                        identityProof: false, 
                        mailProof: false,
                        review: false,
                        payment: true})
                    break
                } else {
                    setSlide({
                        addressValidate: false, 
                        previewCoverage: false, 
                        basicInformation: false, 
                        identityProof: false, 
                        mailProof: true,
                        review: false,
                        payment: false})
                    break
                }
            case 'payment':
                if(direction){
                    // Redirection
                } else {
                    setSlide({
                        addressValidate: false, 
                        previewCoverage: false, 
                        basicInformation: false, 
                        identityProof: false, 
                        mailProof: false,
                        review: true,
                        payment: false})
                    break
                }



            default:
                return
        }

    }

    //////////////////////////////////////////////////////////////
    // exitSignUp
    //////////////////////////////////////////////////////////////
    function exitSignUp(){
        navigate('/')
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // HTML
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div className="screen_background">
            <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
            <div className="signup_screen_foreground">
                    <div className="signup_inner_section">
                        <AddressValidate 
                            display={slide.addressValidate} 
                            setAddress={addAddress} 
                            setRepresentatives={setRepresentatives} 
                            slide={moveSlide}>
                        </AddressValidate>

                        <PreviewCoverage
                            display={slide.previewCoverage}
                            representatives={representatives}
                            slide={moveSlide}>
                        </PreviewCoverage>

                        <BasicInformation
                            display={slide.basicInformation}
                            setGeneralInfo={addBasicInformation}
                            slide={moveSlide}>
                        </BasicInformation>

                        <IdentityProof
                            display={slide.identityProof}
                            setIdentity={addIdentityProof}
                            slide={moveSlide}>
                        </IdentityProof>

                        <MailProof
                            display={slide.mailProof}
                            setMailProof={addMailProof}
                            slide={moveSlide}>
                        </MailProof>

                        <ReviewInformation
                            display={slide.review}
                            information={customerObject}
                            slide={moveSlide}
                            createAccount={createAccount}>
                        </ReviewInformation>

                        <Payment
                            display={slide.payment}
                            slide={moveSlide}
                            customerData={customerObject}
                            clientSecretKey={clientSecretKey}>
                        </Payment>
                    </div>    
            </div>
        </div>
    )
}
