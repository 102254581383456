import React, {useState, useEffect} from 'react'
import './activityHistory.css'
import {
    Routes,
    Route,
    useSearchParams,
    BrowserRouter,
    useNavigate
  } from "react-router-dom"

import TokenService from '../utilities/token.service'
import API from "../utilities/api"
import ErrorWindow from '../errorWindow/errorWindow'
import Header from '../header/header'
import Navigation from '../navigation/navigation'

import NoDoc from '../images/noDoc.png'

export default function ActivityHistory() {
    const [queryParameters] = useSearchParams()
    const [contentDisplay, setContentDisplay] = useState('none')

    const [historyLoad, setHistoryLoad] = useState(false)
    const [skipCount, setSkipCount] = useState(0)

    // First Render
    useEffect(() => {
        window.scrollTo(0, 0)
        getMyHistory()
        
    }, []);


    const [history, setHistory] = useState([])

    // ERROR RELATED ///////////////////////////////////////////////////////////////////////////////
    ////////////////////////////////////////////////////////////////////////////////////////////////
    const [errorDisplay, setErrorDisplay] = useState('none')
    const [errorDetails, setErrorDetails] = useState({title: '', description: ''})

    function closeError(){
        setErrorDisplay('none')
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////


    // NAVIGATION RELATED ///////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////
    const [pageContentsDisplay, setPageContentsDisplay] = useState('block')
    const [navigationDisplay, setNavigationDisplay] = useState('none')

    function hidePage(){
        setPageContentsDisplay('none')
        setNavigationDisplay('block')
    }

    function displayPage(){
        setPageContentsDisplay('block')
        setNavigationDisplay('none')
    }
    /////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // Load History
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    async function getMyHistory(){

        if(historyLoad){
            return
        }

        setHistoryLoad(true)

        let historyRequest = {
            skipCount: skipCount
        }

        await API.post('getMyActivity', historyRequest)
        .then(res => {

            console.log(res.data)

            setHistory(prevState => [...prevState, ...res.data.history]);

            setSkipCount(res.data.skipCount)
            
            setTimeout(() => {
                setHistoryLoad(false)
            }, 1000);
        })
        .catch(err => { 
            setErrorDetails(err)
            setErrorDisplay('block')
        })
    }

    return (
        <div className='screen_background activity_history_screen_background'>
            <Header hideFunction={hidePage} openFunction={displayPage} navDisplay={navigationDisplay}></Header>
            <Navigation navigationDisplay={navigationDisplay}></Navigation>
            <ErrorWindow display={errorDisplay} details={errorDetails} closeError={closeError}></ErrorWindow>
            <div style={{display: pageContentsDisplay}} className='activity_history_screen_foreground'>
            <div style={{display: history.length == 0 ? 'block' : 'none'}} className='activity_history_section'>
                <img className='activity_history_no_doc' src={NoDoc}></img>
                <div className='activity_history_text'>You currently do not have any historical activity. You will need to participate in the activities if you want to see historical transactions.</div>     
            </div>

                {/* <div className='activity_history_main_title '>Activity History</div> */}

                {
              history.map((activity) => 
                  <div className='activity_history_section'>
                    <div className='activity_history_snippet_when'>{activity.actionReadable}</div>
                    <div className='activity_history_snippet_session' style={{display: activity.actionType === 'advertise' || activity.actionType === 'select' ? "block" : "none"}}>VoterStack Advertise & Select Session #{activity.sessionID}</div>
                    <div className='activity_history_snippet_session' style={{display: activity.actionType === 'debate' || activity.actionType === 'choose' ? "block" : "none"}}>VoterStack Debate & Choose Session #{activity.sessionID}</div>

                    <hr></hr>

                    
                        <div className='activity_history_snippet_what' style={{display: activity.actionType === 'advertise' ? 'block' : 'none'}}>You had <span style={{fontWeight: 'bold'}}>advertised</span> the following statement as a top {activity.districtType.toLowerCase()} priority:</div>
                        <div className='activity_history_snippet_what' style={{display: activity.actionType === 'select' ? 'block' : 'none'}}>You had <span style={{fontWeight: 'bold'}}>selected</span> the following statement as a top {activity.districtType.toLowerCase()} priority:</div>
                        <div className='activity_history_snippet_what' style={{display: activity.actionType === 'debate' ? 'block' : 'none'}}>You had <span style={{fontWeight: 'bold'}}>{activity.position == "support" ? "supported" : "opposed"}</span> the following {activity.districtType.toLowerCase()} top priority statement:</div>
                        <div className='activity_history_snippet_what' style={{display: activity.actionType === 'choose' ? 'block' : 'none'}}>You had <span style={{fontWeight: 'bold'}}>chosen</span> the following statement as a top {activity.districtType.toLowerCase()} argument:</div>
                        <div className='activity_history_content_box'>
                            <div className='activity_history_snippet_content'>"{activity.content}"</div>
                        </div>

                        <div className='activity_history_snippet_what' style={{display: activity.actionType === 'debate' && activity.positionArgument != null ? 'block' : 'none', marginTop: '.5rem'}}>You had also created the following {activity.position == "support" ? "supporting" : "opposing"} argument:</div>
                        <div className='activity_history_content_box'>
                        <div className='activity_history_snippet_content' style={{display: activity.actionType === 'debate' && activity.positionArgument != null ? 'block' : 'none'}}>"{activity.positionArgument}"</div>
                        </div>
                  </div>
            )}

                
            </div>
        </div>
    )
}
