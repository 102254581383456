import React, {useRef} from 'react'
import './support.css'
import {
    Routes,
    Route,
    useSearchParams,
    BrowserRouter,
    useNavigate
  } from "react-router-dom"

import supportImage from '../images/support1.png'
import PhoneContact from '../images/phoneContact.png'
import EmailContact from '../images/emailContact.png'

export default function Support() {
    const navigate = useNavigate()

    /////////////////////////////////////////////////////////////////////
    // ReturnHome
    /////////////////////////////////////////////////////////////////////
    function returnHome(){
      navigate('/')
    }

    return (
    <div className="screen_background">
        <div className='support_screen_foreground'>
            <div className='support_section'>
                <div className='support_title_grid'>
                    <img className='support_title_image' src={supportImage}></img>
                    <div className='support_section_title'>Support</div>
                </div>
                <hr></hr>
                <div className='support_instructions'>We apologize you are experiencing an issue. Our team takes your unfortunate experience seriously. Therefore, we would love to help you work through the problem and resolve it as soon as possible. Please contact us with one of the following options below:</div>
                <div className='support_image_title_grid'>
                <img className='support_contact_image' src={PhoneContact}></img>
                <div className='support_contact_label'>(612) 425-4442</div>
                </div>
                <div></div>
                <div className='support_image_title_grid'>
                <img className='support_contact_image' src={EmailContact}></img>
                <div className='support_contact_label'>support@voterstack.com</div>
                </div>

                <div className='common_button common_submit_button' onClick={()=>returnHome()}>Return Home</div>
            </div>
        </div>

    </div>
    )
}
